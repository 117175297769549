<template>
  <div class="moreActs">
    <div class="beijingtupian5">
      <div class="waitao">
        <h6 class="title27">CORPORATE ACTIVITIES</h6>
        <h6 class="title28">公司活动</h6>
        <router-link :to="'/Activities'" class="fanhui"
          ><img
            src="../assets/fanhuiicon.svg"
            style="position: absolute; top: 12px; left: 17px"
          />
          <p class="title29">返回上一级</p></router-link
        >
      </div>
    </div>
    <div style="width: 1180px; height:100%; margin: 80px auto; position: relative;display:flex;justify-content:space-between">
      <div style="width: 852px;">
        <h6 class="title31">{{ activedetalis.title }}</h6> 
        <h6 class="title32">发布于 {{ activedetalis.date }}</h6>
        <div class="content" v-html="activedetalis.content"></div> 
      </div>
      <div class="nav">
        <div class="blueline"></div>
        <h6 class="title33">文章列表</h6>
        <div v-for="(item, index) in activeList" :key="index">
        <div :class="item._id==activeid?'titlelan':'title34'" @click="getActiveById(item._id)" >
          {{ item.title }}
        </div>
        <p class="time1">发布于 {{ item.date }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="content" v-show="sel === 0"> 
      <h6 class="title31">天卓培训｜欢迎加入</h6>
      <h6 class="title32">发布于 2021年11月30日</h6>
      <p class="intro68">首先欢迎各位伙伴加入天卓科技，我们的队伍又壮大了！为了使大家快速了解公司，熟悉工作环境，进入工作状态，人力资源部精心筹备的新员工入职培训，如期而至。</p>
      <img src ="../assets/pt60@2x.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">小伙伴们用简单的绘画，来描绘家乡、兴趣、喜欢的美食以及自己的星座。通过大家对图画的互动猜测，拉近彼此间的距离，快速的抓住对方特点，进行记忆~</p>
      <div class="tpzu2">
        <img src ="../assets/pt61@2x.png" style="width:276px;height:180px">
        <img src ="../assets/pt62@2x.png" style="width:276px;height:180px">
        <img src ="../assets/pt63@2x.png" style="width:276px;height:180px">
      </div>  
      <p class="intro69">有形象的家乡美食“德州扒鸡”，也有“只要我不说你永远猜不到”的“午门”…… 抽象派、野兽派、写实派画师不断涌现。相同的爱好者往往是无论你画的多“草率”都能找到懂你的人！</p>
      <img src ="../assets/pt64@2x.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">本期培训的全部内容由人力资源部田新新为大家进行讲解。</p>
      <p class="intro69">分别从公司概况、主营业务、企业文化活动和公司规章制度几个方面做了详细的介绍。在了解公司及业务的前提下，认同企业文化和企业价值观，遵守公司的各项规章制度，在天卓的平台发挥自己最大的能量！共同见证公司发展的每一步！</p>
      <p class="intro69">每期必不可少的《非暴力沟通》课程，用多样化的案例带大家进入场景化感受。工作中学会如何正向有效沟通，至关重要！</p>
      <div class="tpzu1">
        <img src ="../assets/pt65@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt66@2x.png" style="width:420px;height:200px">
      </div>
      <img src ="../assets/pt67@2x.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro70">天卓科技欢迎各位新伙伴的加入！</p>
      </div> 
    </div>  
    <div class="content" v-show="sel === 1">
      <div class="middle19">
      <h6 class="title31">天卓福利｜感恩节，感谢你</h6>
      <h6 class="title32">发布于 2021年11月29日</h6>
      <p class="intro68">一直以来，天卓科技始终把“家”的理念融入到公司的日常生活中。从“你”入职，“欢迎加入天卓科技大家庭”的欢迎词，到成为“我们”，一同并肩携手共进，见证公司的成长与发展，都充分体现着彼此的认同与信任。</p>
      <img src ="../assets/pt68@2x.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">我们的感恩节，从你的“表白”开始。手写卡片满满真诚，大家纷纷表达出对公司、领导和同事的浓浓爱意。（也展示了谁写得一手好字）</p>
      <div class="tpzu1">
        <img src ="../assets/pt69@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt70@2x.png" style="width:420px;height:200px">
      </div>
      <div class="tpzu1">
        <img src ="../assets/pt71@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt72@2x.png" style="width:420px;height:200px">
      </div>
      <p class="intro69">现场抽取的10位幸运小伙伴，获得了公司精心准备的礼品。没有中奖的也不要着急，一起期待下次活动吧！</p>
       <div class="tpzu1">
        <img src ="../assets/pt73@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt74@2x.png" style="width:420px;height:200px">
      </div>
      <p class="intro69">天卓科技非常注重企业文化和员工感受。公司不仅仅是工作，更是希望大家快乐工作！活动的美食诱惑＆游戏环节，必不可少。</p>
      <div class="tpzu2">
        <img src ="../assets/pt75@2x.png" style="width:276px;height:180px">
        <img src ="../assets/pt76@2x.png" style="width:276px;height:180px">
        <img src ="../assets/pt77@2x.png" style="width:276px;height:180px">
      </div>
      <div class="tpzu1">
        <img src ="../assets/pt78@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt79@2x.png" style="width:420px;height:200px">
      </div>
       <div class="tpzu1">
        <img src ="../assets/pt80@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt81@2x.png" style="width:420px;height:200px">
      </div>
      <p class="intro69">公司看的到每一位员工闪闪发亮！在这个适合感谢的日子里，天卓科技对每一位家人，表示由衷的感谢。感谢大家的付出和努力，用你们的专业和专注，在不同的岗位发挥着巨大的能量！</p>
      </div>
    </div>  
    <div class="content" v-show="sel === 2">
      <div class="middle20">
      <h6 class="title31">天卓新业务｜拓展跨境电商市场</h6>
      <h6 class="title32">发布于 2021年11月15日</h6>
      <p class="intro68">北京天卓科技成立于2015年，经过多年的高速发展，已成为优质的新媒体全域营销运营方案商。依托北京公司在营销上的优势和广州公司的供应链资源，天卓科技成立了跨境电商事业部。</p>
      <p class="intro69">我们是阿里的SKA客户，营销资源充足，经营范围涵盖服装家居类、3C数码类、电动车等多种品类的产品，业务辐射整个欧美地区，且正在向其他地区持续扩展。</p>
      <img src ="../assets/pt82@2x.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">作为计划B2B/B2C双向并行的部门，我们秉持为消费者服务，注重细节，赢在细节的理念，满足消费者多样化的需求。专注服务海外市场，通过充分且多维度的市场调研，结合强大的供应链和营销能力，打造爆款。</p>
      <img src ="../assets/pt83@2x.png" style="width:852px;height:400px;margin-top:20px">
      <div class="tpzu1">
        <img src ="../assets/pt84@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt85@2x.png" style="width:420px;height:200px">
      </div>
      <div class="tpzu1">
        <img src ="../assets/pt86@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt87@2x.png" style="width:420px;height:200px">
      </div>
      <p class="intro69">希望用数字化的方式连接全球市场和中国优质供应链，打造数字化时代的全球化品牌。致力于将中国制造，中国创造带向世界！</p>
      <img src ="../assets/pt88@2x.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">特别祝贺跨境电商事业部程溢艺同学完成首单交易！同时祝公司业绩蒸蒸日上！</p>
      </div>
    </div>  
    <div class="content" v-show="sel === 3">
          <div class="middle21">
      <h6 class="title31">天卓生日会｜万圣节限定</h6>
      <h6 class="title32">发布于 2021年10月29日</h6>
      <p class="intro68">天卓万圣节主题生日趴，氛围感拉满，欢迎走进专属你们的生日会～</p>
       <img src ="../assets/pt89@2x.png" style="width:852px;height:400px;margin-top:20px">
       <p class="intro69">我们的活动，从寻找南瓜头开始。看来在“翻箱倒柜”的能力上，Girls完胜！不仅找的快，上镜也是高水准的~</p>
       <div class="tpzu1">
        <img src ="../assets/pt90@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt91@2x.png" style="width:420px;height:200px">
       </div>
       <p class="intro69">忙碌的工作，需要适当的放松，捣蛋要糖，群魔乱舞，一起狂欢，不错过每一个让员工happy的节日。一年中，难得可以光明正大作妖的日子，看看我们为大家准备了什么吧</p>
      <img src ="../assets/pt92@2x.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">在天卓，让每个节日都值得纪念，在天卓，每一场生日会都充满心意，满满的节日气氛，狂欢ing……</p>
      <div class="tpzu2">
        <img src ="../assets/pt93@2x.png" style="width:276px;height:180px">
        <img src ="../assets/pt94@2x.png" style="width:276px;height:180px">
        <img src ="../assets/pt95@2x.png" style="width:276px;height:180px">
      </div>
      <p class="intro69">秋意浓浓的万圣节，生日礼物绝对不能少。公司领导妥妥给“安排”上！</p>
      <div class="tpzu1">
        <img src ="../assets/pt96@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt97@2x.png" style="width:420px;height:200px">
       </div>
       <div class="tpzu1">
        <img src ="../assets/pt98@2x.png" style="width:420px;height:200px">
        <img src ="../assets/pt99@2x.png" style="width:420px;height:200px">
       </div>
      <p class="intro69">许下最美的心愿，送上最好的祝愿，期待下一次的聚会～</p>
      </div>
    </div>  
    <div class="content" v-show="sel === 4">
      <div class="middle13">
      <h6 class="title31">天卓培训｜迎接最好的你（二）</h6>
      <h6 class="title32">发布于 2021年10月25日</h6>
      <p class="intro68">天卓科技新人入职培训第二场如期而至，本场小伙伴的热情与现在的体感温度形成明显对比，暖意呼之欲出。</p>
      <p class="intro69">首先欢迎各位加入天卓科技。按照惯例，依次请小伙伴们自我介绍。爱运动爱美食爱游戏……更爱天卓！伴随着阵阵掌声，破冰游戏登场，让大家尽快的熟络起来。</p>
      <div class="tpzu1">
        <img src ="../assets/pt1@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt2@2X.png" style="width:420px;height:200px">
      </div>
      <p class="intro69">人力资源部王雷分别从公司概况、主营业务、公司规章制度、公司活动等几个方面做了详细的讲解，快速了解公司，熟悉工作环境，进入工作状态。</p>
      <div class="tpzu2">
        <img src ="../assets/pt3@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt4@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt5@2X.png" style="width:276px;height:180px">
      </div>  
      <p class="intro69">人力资源部杜思媛开启了一场【非暴力沟通】的讨论，用生动的案例给你代入式的感受，学会怎样合理沟通在工作中尤为重要，可以大大提升工作效率。</p>
      <div class="tpzu2">
        <img src ="../assets/pt6@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt7@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt8@2X.png" style="width:276px;height:180px">
      </div>  
      <p class="intro69">值得一提的是，本场培训还特意邀请了天卓科技CEO王总，就企业的发展历程、企业目标、企业理念和企业文化，做了充分的解读。</p>
      <img src ="../assets/pt9@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro71">天卓科技不仅仅是你工作的职场，更是充满爱的大家庭，在此欢迎各位的加入！</p>
      <p class="intro71">希望通过所有人的努力，分享共享，共进共勉，使我们的工作和生活变得更好！</p>
      <img src ="../assets/pt10@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro70">天卓科技欢迎各位新伙伴的加入！</p>
      </div>
    </div>  
    <div class="content" v-show="sel === 5">
        <div class="middle14">
      <h6 class="title31">天卓培训｜迎接最好的你（一）</h6>
      <h6 class="title32">发布于 2021年10月11日</h6>
      <p class="intro68">一雨知深秋，一培训便知要迎接新员工。为了让大家更快速的了解公司，熟悉工作环境，进入工作状态，人力资源部精心筹备的新员工入职培训，如期而至。</p>
      <p class="intro69">首先欢迎各位加入天卓科技。这不仅仅是停留在言语上的一句欢迎语，我们也充分的体现在行动上。</p>
      <div class="tpzu1">
        <img src ="../assets/pt11@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt12@2X.png" style="width:420px;height:200px">
      </div>
      <p class="intro69">接下来，让我们熟悉起来吧！有趣的破冰游戏，让我们迅速了解彼此，简短精炼的自我介绍后，根据分组提问回答的方式，进行关键人物的猜测。其间还产生了最佳笔记小哥哥，居然悄咪咪的记录下艾瑞巴蒂的标签关键词！真是团队之光！</p>
      <img src ="../assets/pt13@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">人力资源部王雷分别从公司概况、主营业务、企业文化活动和公司规章制度几个方面做了详细的讲解。</p>
      <p class="intro69">在了解公司和业务的前提下，认同企业文化和企业价值观，遵守公司各项规章制度，在天卓的平台发挥自己最大的能量！</p>
      <div class="tpzu2">
        <img src ="../assets/pt14@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt15@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt16@2X.png" style="width:276px;height:180px">
      </div>  
      <p class="intro69">随后，人力资源部杜思媛又进行了《非暴力沟通》的培训，这是企业内部正向沟通必备课程，她用多样化的案例带大家进入场景化感受。工作中的交流尤为重要，学会怎样沟通，可以大大提升工作效率。</p>
      <p class="intro69">为了更好的为员工提供入职培训，人力资源部还收集了《培训反馈意见表》，在不断完善的同时，也充分听取大家的建议，尽心最好每一期培训。</p>
      <img src ="../assets/pt17@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro70">天卓科技欢迎各位新伙伴的加入！</p>
      </div>
    </div>  
    <div class="content" v-show="sel === 6">
        <div class="middle15">
      <h6 class="title31">天卓福利 | 爱上秋天的下午茶</h6>
      <h6 class="title32">发布于 2021年09月26日</h6>
      <p class="intro68">工作再忙，烦恼再多，美食总能击中我们内心最柔软的地方。每一位努力工作的小伙伴，都应该得到美味的专属奖赏。</p>
      <p class="intro69">秋意渐浓，恰逢中秋国庆双节，一起打卡天卓满满仪式感的中式下午茶。是什么陪伴我们度过了那些饥肠辘辘的下午，是什么止住了你垂涎欲滴的口水，又是什么安抚了你那躁动不安的小舌头~</p>
      <div class="tpzu3">
        <img src ="../assets/pt18@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt19@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt20@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt21@2X.png" style="width:420px;height:200px">
      </div>  
      <p class="intro69">工作再忙，烦恼再多，美食总能击中我们内心最柔软的地方。每一位努力工作的小伙伴，都应该得到美味的专属奖赏。</p>
      <img src ="../assets/pt22@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">快节奏的工作之余，公司为更好的提升团队工作效率，给小伙伴们提供放松休闲的快乐时光，让大家感受到公司大家庭的温暖，定期举办的下午茶活动绝对是不错的选择。</p>
       <div class="tpzu1">
        <img src ="../assets/pt23@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt24@2X.png" style="width:420px;height:200px">
      </div>
      </div>
    </div>  
    <div class="content" v-show="sel === 7">
          <div class="middle16">
      <h6 class="title31">天卓生日会 | 你的小甜蜜</h6>
      <h6 class="title32">发布于 2021年08月16日</h6>
      <p class="intro68">秋天的第一口“爱情”～是天卓给你的无限宠爱～前方高甜来袭！</p>
      <p class="intro69">专属你的盲盒生日蛋糕、必不可少的巧克力、香气四溢无肉不欢、配料满满水果捞、精美的游戏小奖品以及公司特意为寿星们准备的生日好礼。</p>
      <img src ="../assets/pt25@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">仪式感满满，每一个重要的日子都值得被纪念。天卓陪你度过天天年年～</p>
       <div class="tpzu1">
        <img src ="../assets/pt26@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt27@2X.png" style="width:420px;height:200px">
       </div>
      <img src ="../assets/pt28@2X.png" style="width:852px;height:400px;margin-top:20px">
      <img src ="../assets/pt29@2X.png" style="width:852px;height:400px;margin-top:20px">
      <img src ="../assets/pt30@2X.png" style="width:852px;height:400px;margin-top:20px">
      <img src ="../assets/pt31@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">在未来的朝朝暮暮，天卓希望能与你一直相伴。再次，祝福本期宝宝们“生日快乐！”</p>
      </div>
    </div>  
    <div class="content" v-show="sel === 8">
    <div class="middle17"> 
      <h6 class="title31">天卓团建 | 抓住秋天的尾巴</h6>
      <h6 class="title32">发布于 2020年10月28日</h6>
      <p class="intro68">生活不止眼前的苟且，还有诗和远方。走出工作岗位，走进绿色，趁年轻，不负美好时光！</p>
      <p class="intro69">为了进一步加强团队凝聚力，提升各部门间的团结协作能力，营造激情、责任、快乐的工作氛围。天卓科技公司进行了为期两天的团建活动。</p>
      <img src ="../assets/pt32@2X.png" style="width:852px;height:400px;margin-top:20px">
      <p class="intro69">凉爽的秋日，一行人驱车来到北京怀柔，抛开烦恼，丢下工作，忘记减肥，尽情嗨~</p>
      <h6 class="title43">第一站｜青龙峡风景区</h6>
      <p class="intro69">愉快的周五来袭，我和我的小伙伴们出发咯~穿梭在四周青山环抱绿水的青龙峡之中，欣赏着红叶倚山抱石，高山流水中的小小竹筏，山间的潺潺溪流，沿途还能看到明代古长城，一同感受大自然的美妙。</p>
      <div class="tpzu3">
        <img src ="../assets/pt33@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt34@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt35@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt36@2X.png" style="width:420px;height:200px">
      </div>  
      <p class="intro69">嘟嘟嘟，吹泡泡~大家就跟小孩子一样无拘无束的吹着泡泡。初秋迎来了凉爽的秋风和暖阳，也同时开启了新美丽和精彩，平时在岗位的小仙女们，她们竟然集体翘班，出去吹泡泡了！</p>
      <div class="tpzu1">
        <img src ="../assets/pt37@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt38@2X.png" style="width:420px;height:200px">
      </div>
      <p class="intro69">滴滴滴，老司机开车咯~大家来到美丽的风景区，呼吸着新鲜的空气，摆脱工作的烦恼，完完全全玩嗨了，碰碰车场，你争我赶，我说一个数，赶紧上车！</p>
      <div class="tpzu1">
        <img src ="../assets/pt39@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt40@2X.png" style="width:420px;height:200px">
      </div>
      <h6 class="title43">第二站｜分组破冰趣味拓展活动</h6>
      <p class="intro69">第一天大家玩的很累了，晚上早早休息了，第二天早起，团建团建，放松的同时怎么能少得了PK竞技呢（搞事情）。有趣的团队扩展活动，让新加入的小伙伴们，能够更加熟悉天卓这个大家庭~</p>
      <h6 class="title44">第一关·袋鼠运瓜</h6>
      <p class="intro69">从起点开始，队员穿上袋鼠服准备，选手通过跳跃前进的方式将袋鼠服中的球，依次接力传递到终点的筐中，大家飞奔起来，像一道道闪电，活力四射，争先恐后生怕自己慢了一步，现场真是欢快至极。</p>
      <div class="tpzu2">
        <img src ="../assets/pt41@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt42@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt43@2X.png" style="width:276px;height:180px">
      </div>  
      <h6 class="title44">第二关·充气碰碰球</h6>
      <p class="intro69">每队派出一名代表，钻进球内，相互碰撞，这可真是今天最大的看点了，也是最费力气的项目，同事们摩拳擦掌跃跃欲试，比赛一开始，大家全然不见平时的彬彬有礼，火药味十足，势必要把对方撞一个人仰马翻。</p>
      <div class="tpzu2">
        <img src ="../assets/pt44@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt45@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt46@2X.png" style="width:276px;height:180px">
      </div>
       <h6 class="title44">第三关·十拿九稳</h6>
       <p class="intro69">4名参赛队员握住大球拍的把手，另有1名队员负责放置大球，从起点出发一边颠球一边跑向终点，大家奇思妙想，欢声笑语，不知不觉仿佛彼此的距离更近一步。</p>
      <div class="tpzu2">
        <img src ="../assets/pt47@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt48@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt49@2X.png" style="width:276px;height:180px">
      </div>
      <h6 class="title44">第四关·快乐铅笔</h6>
      <p class="intro69">4名参赛队员负责运输铅笔，采用翻转的方式在赛场上移动，从起点到终点用时少者获胜，又是一个团队合作的游戏，小姐姐们亲自上场，巾帼不让须眉，平时办公室里轻声细语的小姑娘们，在这里活脱脱的变成了一个个女汉子。</p>
      <div class="tpzu2">
        <img src ="../assets/pt50@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt51@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt52@2X.png" style="width:276px;height:180px">
      </div>
      <h6 class="title44">第五关·写毛笔字</h6>
      <p class="intro69">不同于我们平时写字那样，这是一个需要全体成员拉着一根线，把一根大毛笔立起来在地上写上“天卓科技”四个字，起初大家都没有头绪，但是随着每一次小成功，大家的心也越来越齐，失败了就重新整理思绪，分解任务最终完成任务，快来欣赏一下大家的成果。</p>
      <div class="tpzu2">
        <img src ="../assets/pt53@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt54@2X.png" style="width:276px;height:180px">
        <img src ="../assets/pt55@2X.png" style="width:276px;height:180px">
      </div>
      <h6 class="title43">第三站｜徒步竞走</h6>
      <p class="intro69">四面群山枕水、碧波荡漾,以山绕水、水映山而取胜，此情此景，真是让大家流连忘返，怎么能就这么一走了之呢。吃完午饭，我们长达8公里的徒步就开始了，这种减肥方式真是让大家又爱又恨呢。就这样一行人，前前后后互相照应，走走停停，完全融入到山林的美景之中。</p>
      <div class="tpzu3">
        <img src ="../assets/pt56@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt57@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt58@2X.png" style="width:420px;height:200px">
        <img src ="../assets/pt59@2X.png" style="width:420px;height:200px">
      </div>  
      <p class="intro69">欢乐时光总是短暂的，但这些愉快的瞬间如同蓄电池，我们在这里充满能量，同时提升各部门间的协作能力，接下来我们将“挑战自我 团队协作 追求卓越 共创辉煌”，天卓科技2020年团建活动圆满结束。</p>
      </div>
    </div>  -->
    <!-- 底部内容 -->
    <vbottom></vbottom>
  </div>
</template> 
<script>
import vbottom from "../components/bottom";
import { activeList, activeById } from "@/api/index";
export default {
  data() {
    return {
      activeid:this.$route.query._id,
      current:"",
      activeList: [],
      activedetalis: {
        title:"",
        date:"",
        content:""
      },
    };
  },
  mounted() {
    this.getActiveList()
    // console.log(1111);
  },
  components: { vbottom },
  methods: {
    getActiveList() {
      activeList().then((data) => {
        this.activeList = data.list;
        console.log(this.$route,'this.$route.query._id');
        if(this.$route.query._id){
          this.getActiveById(this.$route.query._id);
        }else{
          this.getActiveById(this.activeList[0]._id)
        }
      });
    },
    getActiveById(_id) {
        console.log(_id);
       activeById({_id}).then((data) => {
         this.activedetalis = data;
         this.activeid=_id
       });
    },
  },
}; 
</script>
<style scoped>
.content >>> p{
  color:#666666;

}
.moreActs {
  position: relative;
  font-family: PingFangSC-Regular, PingFang SC;
}
.waitao {
  position: relative;
  width: 1180px;
  margin: auto;
}
.beijingtupian5 {
  background: url("../assets/banner5@2X.png");
  width: 100%;
  height: 288px;
  background-size: cover;
}
.title27 {
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #cccccc;
  line-height: 14px;
  padding-top: 117px;
  text-align: center;
}
.title28 {
  width: 1180px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  line-height: 39px;
  margin-top: 10px;
  text-align: center;
}
.fanhui {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  position: absolute;
  top: 201px;
  left: 520px;
}
.title29 {
  width: 80px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  position: absolute;
  top: 12px;
  left: 43px;
}
.middle13 {
  width: 1180px;
  height: 2066px;
  margin: 0 auto;
  position: relative;
}
.middle14 {
  width: 1180px;
  height: 1866px;
  margin: 0 auto;
  position: relative;
}
.middle15 {
  width: 1180px;
  height: 1460px;
  margin: 0 auto;
  position: relative;
}
.middle16 {
  width: 1180px;
  height: 2674px;
  margin: 0 auto;
  position: relative;
}
.middle17 {
  width: 1180px;
  height: 4260px;
  margin: 0 auto;
  position: relative;
}
.middle18 {
  width: 1180px;
  height: 2266px;
  margin: 0 auto;
  position: relative;
}
.middle19 {
  width: 1180px;
  height: 2198px;
  margin: 0 auto;
  position: relative;
}
.middle20 {
  width: 1180px;
  height: 2178px;
  margin: 0 auto;
  position: relative;
}
.middle21 {
  width: 1180px;
  height: 2146px;
  margin: 0 auto;
  position: relative;
}
.title31 {
  width: 420px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
}
.title32 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  margin-top: 10px;
}
.intro68 {
  width: 852px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  margin-top: 30px;
}
.intro69 {
  width: 852px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  margin-top: 20px;
}
.intro71 {
  width: 852px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  margin-top: 20px;
}
.tpzu1 {
  width: 852px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.tpzu2 {
  width: 852px;
  height: 180px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.tpzu3 {
  width: 852px;
  height: 420px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}
.intro70 {
  width: 852px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #005fff;
  line-height: 26px;
  text-align: center;
  margin-top: 10px;
}
.nav {
  width: 268px;
}
.blueline {
  width: 6px;
  height: 20px;
  background: #005fff;
  border-radius: 5px;
}
.title33 {
  width: 80px;
  height: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  position: relative;
  top: -20px;
  left: 16px;
}
.title34 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  cursor:pointer;
}
.titlelan{
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  cursor:pointer;
  color: #005fff;
}
.time1 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.title35 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 50px;
  left: 16px;
}
.time2 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 60px;
  left: 16px;
}
.title36 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 90px;
  left: 16px;
}
.time3 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 100px;
  left: 16px;
}
.title37 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 130px;
  left: 16px;
}
.time4 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 140px;
  left: 16px;
}
.title38 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 170px;
  left: 16px;
}
.time5 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 180px;
  left: 16px;
}
.title39 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 210px;
  left: 16px;
}
.time6 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 220px;
  left: 16px;
}
.title40 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 250px;
  left: 16px;
}
.time7 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 260px;
  left: 16px;
}
.title41 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 290px;
  left: 16px;
}
.time8 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 300px;
  left: 16px;
}
.title42 {
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: 330px;
  left: 16px;
}
.time9 {
  width: 151px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 14px;
  position: relative;
  top: 340px;
  left: 16px;
}
</style>
